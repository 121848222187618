<template>
  <div class="success">
    <img src="@/assets/img/success.svg" alt="">
    <h2>您的训练方案已经生成</h2>
    <el-button type="primary" @click="toPreList()">去查看</el-button>
  </div>
</template>
<script>
export default {
  name: "Success",
  data() {
    return {    
    }
  },
  methods: {      
    toPreList(){
      //跳转到处方列表
      this.$router.push({path: "/"})
    }   
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.success {
  img {
    display: block;
    width: 1.23rem;
    margin: 0.94rem auto 0.19rem;
  }

  h2 {
    line-height: .24rem;
    font-size: .14rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.67rem;
  }

  .el-button {
    display: block;
    width: 1.76rem;
    margin: 0 auto;
  }
}
</style>